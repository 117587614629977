import { Box, Spinner } from "@chakra-ui/react";
import { AsyncSelect as ReactAsyncSelct, Select as ReactSelect } from "chakra-react-select";

export const AsyncSelect = (props) => {
  return (
    <Box flex="1">
      <ReactAsyncSelct
        loadingMessage={() => <Spinner size="xs" />}
        noOptionsMessage={() => "Nenhum registro encontrado"}
        selectedOptionStyle="check"
        selectedOptionColorScheme="main"
        {...props}
      />
    </Box>
  );
};

export const SyncSelect = (props) => {
  return (
    <Box flex="1">
      <ReactSelect
        loadingMessage={() => <Spinner size="xs" />}
        noOptionsMessage={() => "Nenhum registro encontrado"}
        selectedOptionStyle="check"
        selectedOptionColorScheme="main"
        {...props}
      />
    </Box>
  );
};
