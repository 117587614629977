import React, { createContext, useMemo, useCallback, useState } from "react";
import jwt_decode from "jwt-decode";
import { api } from "@core";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [timestamp, setTimestamp] = useState(Date.now());

  const currentAuth = useMemo(() => {
    try {
      const token = localStorage.getItem("@token");
      return jwt_decode(token);
    } catch (error) {
      return null;
    }
  }, [timestamp]);

  const sendVerificationCode = useCallback((email) => {
    return api.put("/sign-in", { email });
  }, []);

  const signIn = useCallback(async (_id, verificationCode) => {
    const token = await api.post(`/sign-in`, { _id, verificationCode });
    localStorage.setItem("@token", token);
    setTimestamp(Date.now());
  }, []);

  const signOut = useCallback(async () => {
    localStorage.removeItem("@token");
    setTimestamp(Date.now());
  }, []);

  return <AuthContext.Provider value={{ currentAuth, sendVerificationCode, signIn, signOut }}>{children}</AuthContext.Provider>;
};
