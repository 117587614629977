import { useEffect } from "react";
import { Heading, Icon, Text, HStack, Box, IconButton } from "@chakra-ui/react";
import { Lottie, useCustomToast, EventEmitter } from "@core";
import { MdClose } from "react-icons/md";
import animationData from "@core/assets/lottie/wifi.json";

const CustomToast = ({ onClose }) => {
  return (
    <HStack color="white" p="15px" bg="gray.900" borderRadius="lg" position="relative">
      <Box w="40px" h="40px" bg="gray.800" borderRadius="full">
        <Lottie animationData={animationData} style={{ width: 40, height: 40 }} />
      </Box>
      <Box flex="1">
        <Heading size="xs">Sem conexão</Heading>
        <Text fontSize="xs" fontWeight="semibold">
          Não foi possível estabelecer a comunicação com o servidor.
        </Text>
      </Box>
      <IconButton
        size="xs"
        right="2px"
        top="2px"
        position="absolute"
        variant="ghost"
        colorScheme="whiteAlpha"
        icon={<Icon as={MdClose} color="white" boxSize="15px" />}
        onClick={onClose}
      />
    </HStack>
  );
};

export const NetworkError = () => {
  const toast = useCustomToast();

  useEffect(() => {
    const onOpen = () => {
      const id = "network-error";
      if (!toast.isActive(id)) toast({ id, position: "bottom", render: (props) => <CustomToast {...props} />, duration: 5000 });
    };
    EventEmitter.subscribe("onNetworkError", onOpen);
    return () => EventEmitter.unsubscribe("onNetworkError");
  }, [toast]);

  return null;
};
