import React, { useState, useEffect, useMemo, useCallback, memo, useContext } from "react";
import _ from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  SlideFade,
  Spinner,
  Switch,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import * as yup from "yup";
import { messages } from "consts";
import { Breadcrumb, useApiGet, useCustomToast, useDocumentTitle, api, BoxData, DocumentHistory } from "@core";
import { MdChevronLeft, MdHistory } from "react-icons/md";
import { Content } from "pages/Private/Container";
import UserGroupsDetailsContext from "./context";
import permissionGroups from "./permissionGroups";
import { PermissionedContainer } from "components";

const PermissionItem = memo(({ value }) => {
  const { formData, setFormData } = useContext(UserGroupsDetailsContext);
  const index = useMemo(() => _.indexOf(formData.permissions, value), [value, formData.permissions]);

  const handleChange = useCallback(() => {
    setFormData((state) => {
      const permissions = [...state.permissions];
      if (index == -1) permissions.push(value);
      else permissions.splice(index, 1);
      return { ...state, permissions };
    });
  }, [value, index]);

  return <Checkbox colorScheme="main" isChecked={index !== -1} onChange={handleChange} />;
});

export const UserGroupsDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar grupo de usuário" : "Nova grupo de usuário");
  const navigate = useNavigate();
  const location = useLocation();
  const [data, isLoadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/user-groups/${_id}` }), [_id]));
  const [formData, setFormData] = useState({ permissions: [] });
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const toast = useCustomToast();

  useEffect(() => {
    const formData = data ?? { permissions: [], isActive: true };
    setFormData(formData);
  }, [data]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id ? await api.put(`/user-groups/${_id}`, data) : await api.post("/user-groups", data);
        navigate(`/settings/records/user-groups/edit/${saved._id}`, { replace: true });
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
      } catch (error) {
        if (error.isHandled) return;
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, navigate]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        title: yup.string().required(messages.error.required),
        permissions: yup.array().required(messages.error.required),
      });
      await schema.validate(formData, { abortEarly: false });
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  const handleSelectAll = useCallback((key) => {
    setFormData((state) => {
      const mapped = [":read", ":create", ":update", ":delete"].map((v) => key.concat(v));
      const filtered = _.filter(state.permissions, (o) => o.search(key) === -1);
      const permissions = _.concat(filtered, mapped);
      return { ...state, permissions };
    });
  }, []);

  const handleCleanAll = useCallback((key) => {
    setFormData((state) => {
      const permissions = _.filter(state.permissions, (v) => v.search(key) === -1);
      return { ...state, permissions };
    });
  }, []);

  return (
    <UserGroupsDetailsContext.Provider value={{ formData, setFormData }}>
      <Content>
        <HStack justify="space-between">
          <HStack spacing={{ base: "10px", lg: "20px" }}>
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "configurações" },
                { to: "/settings#records", label: "cadastros" },
                { to: "/settings/records/user-groups", label: "demandas" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
          {_id && <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />}
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Grupo de usuário</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ? data?.title : "Novo cadastro"}</Text>
          </Box>
          <HStack>
            <FormControl display="flex" alignItems="center">
              <FormLabel fontSize="sm" mb="0">
                Ativo?
              </FormLabel>
              <Switch
                colorScheme="main"
                isChecked={formData.isActive}
                onChange={() => setFormData((state) => ({ ...state, isActive: !state.isActive }))}
              />
            </FormControl>
          </HStack>
        </HStack>

        <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={4}>
          <GridItem colSpan={{ base: 4, lg: 2 }}>
            <BoxData label="NID" value={formData.nid?.toLocaleString() ?? "-"} />
          </GridItem>
          <GridItem colSpan={{ base: 8, lg: 10 }}>
            <FormControl isRequired={true} isInvalid={formErrors.title}>
              <FormLabel fontSize="sm">Título</FormLabel>
              <Input value={formData.title ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, title: target.value }))} />
              <FormErrorMessage>{formErrors.title}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>

        <Divider my={8} />

        <Box mb={6}>
          <Heading size="sm">Permissões</Heading>
          {_.isString(formErrors.permissions) && (
            <Text fontSize="sm" color="red.500">
              {formErrors.permissions}
            </Text>
          )}
        </Box>

        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Ativo</Th>
              <Th>Visualizar</Th>
              <Th>Incluir</Th>
              <Th>Alterar</Th>
              <Th>Excluir</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {permissionGroups.map(({ value, label }) => (
              <Tr _hover={{ _light: { bg: "gray.100" }, _dark: { bg: "gray.900" } }}>
                <Td w="100%">{label}</Td>
                <Td textAlign="center">
                  <PermissionItem value={value.concat(":read")} />
                </Td>
                <Td textAlign="center">
                  <PermissionItem value={value.concat(":create")} />
                </Td>
                <Td textAlign="center">
                  <PermissionItem value={value.concat(":update")} />
                </Td>
                <Td textAlign="center">
                  <PermissionItem value={value.concat(":delete")} />
                </Td>
                <Td textAlign="center">
                  <HStack>
                    <Button size="xs" variant="outline" onClick={() => handleSelectAll(value)}>
                      todos
                    </Button>
                    <Button size="xs" onClick={() => handleCleanAll(value)}>
                      limpar
                    </Button>
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Content>

      <PermissionedContainer required={"user-groups:".concat(_id ? "update" : "create")}>
        <Divider />
        <SlideFade in={true} offsetY="20px">
          <HStack p="20px">
            <Button size="sm" colorScheme="main" isLoading={isLoadingData || isLoadingSaveData} onClick={handleSubmit}>
              salvar
            </Button>
            <Button size="sm" variant="ghost" onClick={() => navigate(-1)}>
              voltar
            </Button>
          </HStack>
        </SlideFade>
      </PermissionedContainer>

      {_id && <DocumentHistory path={`/user-groups/${_id}/history`} isOpen={isOpenDocumentHistory} onClose={onCloseDocumentHistory} />}
    </UserGroupsDetailsContext.Provider>
  );
};
