import React, { useContext, useMemo, useState } from "react";
import _ from "lodash";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Icon,
  IconButton,
  Link,
  Spinner,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useApiGet, Paginator } from "@core";
import { MdClose, MdDownload, MdRefresh } from "react-icons/md";
import moment from "moment";
import FileListContext from "./context";

const Downloads = ({ path = "/private/files" }) => {
  const { selected, setSelected } = useContext(FileListContext);
  const [page, setPage] = useState(0);
  const [perPage] = useState(50);
  const [data, isLoadingData, refreshData] = useApiGet(
    useMemo(
      () => ({
        path: `${path}/${selected?._id}/downloads`,
        params: { page, perPage, sort: { createdAt: -1 } },
        options: { isEnabled: !!selected?._id },
      }),
      [path, selected?._id, page, perPage]
    )
  );

  return (
    <Drawer isOpen={selected} placement="right" onClose={() => setSelected()}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader as={HStack}>
          <Text flex="1" fontSize="md">
            Histórico de downloads
          </Text>
          <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingData} onClick={refreshData} />
          <IconButton size="sm" variant="outline" icon={<Icon as={MdClose} />} onClick={() => setSelected()} />
        </DrawerHeader>
        <Divider />
        <DrawerBody>
          {isLoadingData ? (
            <Center p="40px">
              <Spinner />
            </Center>
          ) : (
            _.size(data?.data) === 0 && (
              <Center paddingTop="40px" paddingBottom="20px">
                <Box textAlign="center">
                  <Icon as={MdDownload} boxSize={20} marginBottom="10px" />
                  <Text fontSize="lg" fontWeight="bold">
                    Nenhum download realizado
                  </Text>
                  <Text fontSize="sm">Ainda não existem downloads realizados deste arquivo para serem exibidos.</Text>
                </Box>
              </Center>
            )
          )}

          <VStack align="stretch" divider={<StackDivider />}>
            {_.map(data?.data, (item) => (
              <HStack key={item._id} py="10px">
                <Center w="40px" h="40px" borderRadius="full" _light={{ bg: "gray.100" }} _dark={{ bg: "gray.800" }}>
                  <Icon as={MdDownload} />
                </Center>
                <Box flex="1">
                  <Text display="inline-block" fontSize="xs">
                    Download realizado em <strong>{moment(item.createdAt).format("DD/MM/YYYY")}</strong> às{" "}
                    <strong>{moment(item.createdAt).format("HH:mm")}</strong> por{" "}
                    <Link as={RouterLink} to={`/users/edit/${item.createdBy._id}`} target="_blank">
                      <strong>{item.createdBy.name}</strong>
                    </Link>
                  </Text>
                </Box>
              </HStack>
            ))}
          </VStack>
        </DrawerBody>
        <Divider />
        <DrawerFooter>
          <Paginator
            loading={isLoadingData}
            page={page}
            size={data?.size}
            perPage={perPage}
            onPaginate={setPage}
            isResultSizeVisible={false}
          />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default Downloads;
