import _ from "lodash";

const permissionGroups = [
  { value: "customers", label: "Clientes" },
  { value: "contracts", label: "Contratos" },
  { value: "users", label: "Usuários" },
  { value: "user-groups", label: "Grupos de usuários" },
  { value: "segments", label: "Segmentos" },
  { value: "contract-templates", label: "Templates de contratos" },
];

export default _.sortBy(permissionGroups, [(o) => o.label]);
