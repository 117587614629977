import React, { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  SlideFade,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import { MdBusiness, MdKeyboardArrowRight, MdMail } from "react-icons/md";
import * as yup from "yup";
import { useCustomToast } from "@core";
import { messages } from "consts";
import { Logo } from "components";
import { AuthContext } from "AuthProvider";
import { __DEV__ } from "index";

export const SignInEmailAndCompany = () => {
  const navigate = useNavigate();
  const { sendVerificationCode } = useContext(AuthContext);
  const [formData, setFormData] = useState(__DEV__ ? { email: "marcotulio.magalhaes@gmail.com" } : {});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSendVerificationCode, setIsLoadingSendVerificationCode] = useState(false);
  const toast = useCustomToast();

  const handleSendVerificationCode = useCallback(
    async ({ email }) => {
      try {
        setIsLoadingSendVerificationCode(true);
        const { _id } = await sendVerificationCode(email);
        navigate(`/sign-in/verification-code`, { state: { _id, email: formData.email } });
      } catch (error) {
        toast({ description: error.message, status: "error", duration: 5000, isClosable: true });
      } finally {
        setIsLoadingSendVerificationCode(false);
      }
    },
    [sendVerificationCode, formData.email]
  );

  const handleSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        const schema = yup.object().shape({
          email: yup.string().email(messages.error.invalidEmail).required(messages.error.required),
        });
        await schema.validate(formData, { abortEarly: false });
        handleSendVerificationCode(formData);
        setFormErrors({});
      } catch (error) {
        const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
        setFormErrors(formErrors);
      }
    },
    [handleSendVerificationCode, formData]
  );

  return (
    <>
      <VStack spacing={2} mb={6}>
        <HStack>
          <Logo width={100} />
          <Box>
            <Heading size="lg" lineHeight="1">
              ConsigSys
            </Heading>
            <Heading size="lg" lineHeight="1" fontWeight="normal">
              Console
            </Heading>
          </Box>
        </HStack>
        <Text fontSize="sm" textAlign="center">
          Por favor, informe suas credenciais para que possamos enviar o código de verificação.
        </Text>
      </VStack>
      <form onSubmit={handleSubmit}>
        <VStack alignItems="stretch" spacing={4}>
          <FormControl isRequired={true} isInvalid={formErrors.email}>
            <FormLabel fontSize="sm">E-mail</FormLabel>
            <InputGroup>
              <InputLeftAddon>
                <Icon as={MdMail} />
              </InputLeftAddon>
              <Input value={formData.email ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, email: target.value }))} />
            </InputGroup>
            <FormErrorMessage>{formErrors.email}</FormErrorMessage>
          </FormControl>
          <Button type="submit" colorScheme="main" rightIcon={<Icon as={MdKeyboardArrowRight} />} isLoading={isLoadingSendVerificationCode}>
            Enviar
          </Button>
        </VStack>
      </form>
    </>
  );
};
