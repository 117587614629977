import React, { createContext, useMemo } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Center, Spinner } from "@chakra-ui/react";
import { useApiGet } from "@core";
import Container from "./Container";
import { Home } from "./Home";
import { UsersList, UsersDetails } from "./Users";
import { CustomersList, CustomersDetails } from "./Customers";
import { ContractTemplatesList, ContractTemplatesDetails } from "./ContractTemplates";
import { ContractsList } from "./Contracts";
import * as Settings from "./Settings";

export const PrivateContext = createContext();

const Private = () => {
  const [currentUser, isLoadingCurrentUser, refreshCurrentUser] = useApiGet(useMemo(() => ({ path: "/current-user" }), []));

  return currentUser ? (
    <PrivateContext.Provider value={{ currentUser, isLoadingCurrentUser, refreshCurrentUser }}>
      <BrowserRouter>
        <Routes>
          <Route element={<Container />}>
            <Route index={true} path="home" element={<Home />} />

            <Route path="users">
              <Route index={true} element={<UsersList />} />
              <Route path="new" element={<UsersDetails />} />
              <Route path="edit/:_id" element={<UsersDetails />} />
            </Route>

            <Route path="customers">
              <Route index={true} element={<CustomersList />} />
              <Route path="new" element={<CustomersDetails />} />
              <Route path="edit/:_id" element={<CustomersDetails />} />
            </Route>

            <Route path="contract-templates">
              <Route index={true} element={<ContractTemplatesList />} />
              <Route path="new" element={<ContractTemplatesDetails />} />
              <Route path="edit/:_id" element={<ContractTemplatesDetails />} />
            </Route>

            <Route path="contracts">
              <Route index={true} element={<ContractsList />} />
            </Route>

            <Route path="settings">
              <Route index={true} element={<Settings.Container />} />

              <Route path="records">
                <Route path="user-groups">
                  <Route index={true} element={<Settings.Records.UserGroupsList />} />
                  <Route path="new" element={<Settings.Records.UserGroupsDetails />} />
                  <Route path="edit/:_id" element={<Settings.Records.UserGroupsDetails />} />
                </Route>

                <Route path="segments">
                  <Route index={true} element={<Settings.Records.SegmentsList />} />
                  <Route path="new" element={<Settings.Records.SegmentsDetails />} />
                  <Route path="edit/:_id" element={<Settings.Records.SegmentsDetails />} />
                </Route>
              </Route>
            </Route>
          </Route>

          <Route path="*" element={<Navigate to="/home" replace={true} />} />
        </Routes>
      </BrowserRouter>
    </PrivateContext.Provider>
  ) : (
    <Center h="100vh">
      <Spinner />
    </Center>
  );
};

export default Private;
