import React, { useContext, useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import _ from "lodash";
import { Divider, FormControl, FormErrorMessage, FormLabel, Grid, GridItem, Heading, HStack, Input, Link, Select } from "@chakra-ui/react";
import InputMask from "react-input-mask";
import { PhoneInput, AsyncSelect, api, BoxData, EmailAutocomplete, personTypes, Address } from "@core";
import { PermissionedContainer } from "components";
import CustomersDetailsContext from "../context";
import Contacts from "./contacts";
import SocialNetworks from "./socialNetworks";

let loadSegmentsTimeout;

const General = () => {
  const { formData, setFormData, formErrors } = useContext(CustomersDetailsContext);

  const handleLoadSegments = useCallback((search, cb) => {
    clearTimeout(loadSegmentsTimeout);
    loadSegmentsTimeout = setTimeout(async () => {
      const response = await api.get("/segments", {
        params: {
          search,
          query: { isActive: true },
          sort: { title: 1 },
          perPage: 20,
          isAutocomplete: true,
        },
      });
      cb(response?.data ?? []);
    }, 1000);
  }, []);

  return (
    <>
      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={{ base: 4, lg: 1 }}>
          <BoxData label="NID" value={formData.nid?.toLocaleString() ?? "-"} />
        </GridItem>
        <GridItem colSpan={{ base: 8, lg: 2 }}>
          <FormControl isRequired={true} isInvalid={formErrors.status}>
            <FormLabel fontSize="sm">Status</FormLabel>
            <Select value={formData.status} onChange={({ target }) => setFormData((state) => ({ ...state, status: target.value }))}>
              <option value="prospect">Prospecto</option>
              <option value="pending">Pendente</option>
              <option value="deployed">Implantado</option>
              <option value="suspended">Suspenso</option>
              <option value="canceled">Cancelado</option>
            </Select>
            <FormErrorMessage>{formErrors.status}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 8, lg: 2 }}>
          <FormControl isRequired={true} isInvalid={formErrors.type}>
            <FormLabel fontSize="sm">Tipo</FormLabel>
            <Select value={formData.type} onChange={({ target }) => setFormData((state) => ({ ...state, type: target.value }))}>
              <option value="pf">Pessoa física</option>
              <option value="pj">Pessoa jurídica</option>
            </Select>
            <FormErrorMessage>{formErrors.type}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isRequired={true} isInvalid={formErrors.name}>
            <FormLabel fontSize="sm">Nome</FormLabel>
            <Input value={formData.name ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, name: target.value }))} />
            <FormErrorMessage>{formErrors.name}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 3 }}>
          <FormControl isRequired={formData.type === "pj"} isInvalid={formErrors.companyName}>
            <FormLabel fontSize="sm">Razão social</FormLabel>
            <Input
              value={formData.companyName ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, companyName: target.value }))}
            />
            <FormErrorMessage>{formErrors.companyName}</FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={{ base: 6, lg: 4 }}>
          <FormControl isRequired={true} isInvalid={formErrors.document}>
            <FormLabel fontSize="sm">{personTypes[formData.type].document}</FormLabel>
            <Input
              as={InputMask}
              mask={personTypes[formData.type].documentMask}
              value={formData.document ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, document: target.value }))}
            />
            <FormErrorMessage>{formErrors.document}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 6, lg: 4 }}>
          <FormControl isInvalid={formErrors.ie}>
            <FormLabel fontSize="sm">Inscrição estadual</FormLabel>
            <Input value={formData.ie ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, ie: target.value }))} />
            <FormErrorMessage>{formErrors.ie}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isRequired={true} isInvalid={formErrors.segment}>
            <HStack mb="0.5em" justifyContent="space-between">
              <FormLabel fontSize="sm" m="0">
                Segmento
              </FormLabel>
              {formData.segment ? (
                <PermissionedContainer required="segments:update">
                  <Link as={RouterLink} to={`/settings/records/segments/edit/${formData.segment._id}`} target="_blank" fontSize="xs">
                    editar cadastro
                  </Link>
                </PermissionedContainer>
              ) : (
                <PermissionedContainer required="segments:create">
                  <Link as={RouterLink} to={`/settings/records/segments/new`} target="_blank" color="blue.500" fontSize="xs">
                    incluir cadastro
                  </Link>
                </PermissionedContainer>
              )}
            </HStack>
            <AsyncSelect
              value={formData.segment}
              defaultOptions
              loadOptions={handleLoadSegments}
              placeholder="Selecione"
              onChange={(segment) => setFormData((state) => ({ ...state, segment }))}
              getOptionValue={({ _id }) => _id}
              formatOptionLabel={({ title }) => title}
              isClearable={true}
            />
            <FormErrorMessage>{formErrors.segment}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>

      <Divider my={8} />

      <Heading size="sm" mb={6}>
        Contato
      </Heading>
      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <PhoneInput
            label="Telefone 1"
            phone={formData.phone1}
            category={formData.phone1Category}
            onChangePhone={(phone1) => setFormData((state) => ({ ...state, phone1 }))}
            onChangeCategory={(phone1Category) => setFormData((state) => ({ ...state, phone1Category }))}
            isRequired={true}
            isInvalid={formErrors.phone1}
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <PhoneInput
            label="Telefone 2"
            phone={formData.phone2}
            category={formData.phone2Category}
            onChangePhone={(phone2) => setFormData((state) => ({ ...state, phone2 }))}
            onChangeCategory={(phone2Category) => setFormData((state) => ({ ...state, phone2Category }))}
            isRequired={false}
            isInvalid={formErrors.phone2}
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <PhoneInput
            label="Telefone 3"
            phone={formData.phone3}
            category={formData.phone3Category}
            onChangePhone={(phone3) => setFormData((state) => ({ ...state, phone3 }))}
            onChangeCategory={(phone3Category) => setFormData((state) => ({ ...state, phone3Category }))}
            isRequired={false}
            isInvalid={formErrors.phone3}
          />
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isInvalid={formErrors.website}>
            <FormLabel fontSize="sm">WebSite</FormLabel>
            <Input
              value={formData.website ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, website: target.value }))}
            />
            <FormErrorMessage>{formErrors.website}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isRequired={true} isInvalid={formErrors.email}>
            <FormLabel fontSize="sm">E-mail</FormLabel>
            <EmailAutocomplete value={formData.email ?? ""} onChange={(email) => setFormData((state) => ({ ...state, email }))} />
            <FormErrorMessage>{formErrors.email}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <FormControl isInvalid={formErrors.email2}>
            <FormLabel fontSize="sm">E-mail 2</FormLabel>
            <EmailAutocomplete value={formData.email2 ?? ""} onChange={(email2) => setFormData((state) => ({ ...state, email2 }))} />
            <FormErrorMessage>{formErrors.email2}</FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>

      <Divider my={8} />

      <Heading size="sm" mb={6}>
        Endereço
      </Heading>
      <Address
        data={formData.address}
        errors={formErrors.address}
        onChange={(data) => setFormData((state) => ({ ...state, address: { ...state.address, ...data } }))}
        isRequired={true}
      />

      <Divider my={8} />

      <Contacts />

      <Divider my={8} />

      <SocialNetworks />
    </>
  );
};

export default General;
