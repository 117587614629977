import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  colors: {
    main: {
      50: "#e1edff",
      100: "#b2caff",
      200: "#82a6fd",
      300: "#5282fa",
      400: "#225ff7",
      500: "#0845dd",
      600: "#0136ad",
      700: "#00267d",
      800: "#00174e",
      900: "#000820",
    },
  },
});
