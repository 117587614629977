import React from "react";
import { useColorModeValue } from "@chakra-ui/react";

export const Logo = ({ width = 50 }) => {
  const color = useColorModeValue("#0d4df6", "white");
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 291.996 194" {...{ width }}>
      <g id="Grupo_14" data-name="Grupo 14" transform="translate(-814.002 -275)">
        <path
          id="Caminho_7"
          data-name="Caminho 7"
          d="M194,194V138h21v-.006A21.479,21.479,0,0,0,235.991,117h56a78.117,78.117,0,0,1-1.572,15.119,77.1,77.1,0,0,1-11.661,27.713,77.716,77.716,0,0,1-34.1,28.078,77.179,77.179,0,0,1-14.547,4.516A78.111,78.111,0,0,1,215,194v0Zm-97,0a97.731,97.731,0,0,1-19.548-1.97,96.473,96.473,0,0,1-34.685-14.6A97.281,97.281,0,0,1,7.622,134.756,96.509,96.509,0,0,1,1.97,116.548,97.711,97.711,0,0,1,0,97H56a40.963,40.963,0,0,0,40.992,41H138V97h0A40.963,40.963,0,0,0,97.008,56H97V0a97.731,97.731,0,0,1,19.548,1.97,96.474,96.474,0,0,1,34.685,14.6,97.546,97.546,0,0,1,26.2,26.2q1.784,2.642,3.394,5.4,2.22-1.113,4.5-2.079a77.179,77.179,0,0,1,14.547-4.516A78.137,78.137,0,0,1,215,40V96A21.478,21.478,0,0,0,194,117h0v77Zm138.992-77h0A21.477,21.477,0,0,0,215,96V40a78.137,78.137,0,0,1,15.119,1.572,77.108,77.108,0,0,1,27.712,11.661,77.715,77.715,0,0,1,28.078,34.1,77.186,77.186,0,0,1,4.516,14.548A78.115,78.115,0,0,1,292,117ZM0,97A97.7,97.7,0,0,1,1.97,77.451a96.473,96.473,0,0,1,14.6-34.685A97.281,97.281,0,0,1,59.243,7.622,96.509,96.509,0,0,1,77.451,1.97,97.731,97.731,0,0,1,97,0V56h-.008A40.964,40.964,0,0,0,56,97H0Z"
          transform="translate(814.002 275)"
          fill={color}
        />
        <g id="Grupo_13" data-name="Grupo 13">
          <path id="Caminho_2" data-name="Caminho 2" d="M0,0H97V56H0Z" transform="translate(911.002 413)" fill="#031643" opacity="0.3" />
          <path
            id="Caminho_3"
            data-name="Caminho 3"
            d="M41,138V97H97v41Zm0-41h0a40.735,40.735,0,0,0-3.222-15.958,40.841,40.841,0,0,0-8.787-13.032,40.841,40.841,0,0,0-13.032-8.787A40.715,40.715,0,0,0,.008,56H0V0A97.731,97.731,0,0,1,19.548,1.97,96.509,96.509,0,0,1,37.756,7.622a97.013,97.013,0,0,1,16.477,8.943A97.747,97.747,0,0,1,68.589,28.41,97.747,97.747,0,0,1,80.434,42.766a97.118,97.118,0,0,1,8.943,16.477,96.509,96.509,0,0,1,5.652,18.208A97.7,97.7,0,0,1,97,97Z"
            transform="translate(911.002 275)"
            fill="#031643"
            opacity="0.6"
          />
          <path
            id="Caminho_5"
            data-name="Caminho 5"
            d="M71,111H15a97.706,97.706,0,0,1,1.97-19.548,96.473,96.473,0,0,1,14.6-34.685A97.281,97.281,0,0,1,74.244,21.623,96.506,96.506,0,0,1,92.452,15.97,97.708,97.708,0,0,1,112,14V70h-.008A40.964,40.964,0,0,0,71,111Z"
            transform="translate(799.002 261)"
            fill="#031643"
            opacity="0.5"
          />
          <path
            id="Caminho_6"
            data-name="Caminho 6"
            d="M56,0H0A97.706,97.706,0,0,0,1.97,19.548,96.506,96.506,0,0,0,7.622,37.756a97.09,97.09,0,0,0,8.943,16.477A97.724,97.724,0,0,0,28.41,68.589,97.725,97.725,0,0,0,42.766,80.434a97.022,97.022,0,0,0,16.477,8.943,96.507,96.507,0,0,0,18.208,5.652A97.709,97.709,0,0,0,97,97V41h-.008a40.72,40.72,0,0,1-15.951-3.221,40.841,40.841,0,0,1-13.032-8.787,40.841,40.841,0,0,1-8.787-13.032A40.736,40.736,0,0,1,56,0Z"
            transform="translate(814.002 372)"
            fill="#031643"
            opacity="0.4"
          />
          <path
            id="Caminho_4"
            data-name="Caminho 4"
            d="M56,0H0A78.129,78.129,0,0,0,1.573,15.119,77.133,77.133,0,0,0,6.089,29.667a77.507,77.507,0,0,0,7.145,13.165A78.118,78.118,0,0,0,22.7,54.3a78.172,78.172,0,0,0,11.47,9.464A77.508,77.508,0,0,0,47.332,70.91a77.144,77.144,0,0,0,14.547,4.516A78.134,78.134,0,0,0,77,77v-56a21.359,21.359,0,0,1-7.869-1.684A21.446,21.446,0,0,1,62.3,14.7a21.424,21.424,0,0,1-4.608-6.835A21.342,21.342,0,0,1,56,0Z"
            transform="translate(1105.998 391.999) rotate(180)"
            fill="#031643"
            opacity="0.1"
          />
          <path
            id="Caminho_1"
            data-name="Caminho 1"
            d="M0,77V21H21V21a21.375,21.375,0,0,0,7.869-1.684A21.456,21.456,0,0,0,35.7,14.7a21.445,21.445,0,0,0,4.608-6.835A21.328,21.328,0,0,0,41.991,0H98a78.115,78.115,0,0,1-1.572,15.119,77.167,77.167,0,0,1-4.516,14.548,77.572,77.572,0,0,1-7.145,13.165A78.119,78.119,0,0,1,75.3,54.3a78.174,78.174,0,0,1-11.47,9.464A77.544,77.544,0,0,1,50.664,70.91a77.178,77.178,0,0,1-14.547,4.516A78.137,78.137,0,0,1,21,77v0Z"
            transform="translate(1008.002 391.999)"
            fill="#031643"
            opacity="0.2"
          />
        </g>
      </g>
    </svg>
  );
};
