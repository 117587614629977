import React, { useState, useEffect, useMemo, useCallback } from "react";
import _ from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  SlideFade,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Breadcrumb, DocumentHistory, api, useApiGet, useCustomToast, useDocumentTitle, Portal } from "@core";
import * as yup from "yup";
import { messages } from "consts";
import { PermissionedContainer } from "components";
import { Content } from "pages/Private/Container";
import CustomersDetailsContext from "./context";
import General from "./general";
import Transaction from "./transaction";
import Files from "./files";
import CleanCustomerData from "./cleanCustomerData";
import GenerateContract from "./generateContract";
import { MdChevronLeft, MdHistory, MdMoreHoriz } from "react-icons/md";
import { FiTrash } from "react-icons/fi";
import { HiOutlineDocumentText } from "react-icons/hi";

const setupFormData = (data) => {
  const formData = data ?? {
    status: "prospect",
    type: "pj",
    phone1Category: "cellphone",
    phone2Category: "cellphone",
    phone3Category: "cellphone",
  };
  if (_.size(formData.contacts) === 0) formData.contacts = [{ phoneCategory: "cellphone" }];
  if (_.size(formData.socialNetworks) === 0) formData.socialNetworks = [{}];
  return formData;
};

export const CustomersDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar cliente" : "Novo cliente");
  const navigate = useNavigate();
  const location = useLocation();
  const [data, isLoadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/customers/${_id}` }), [_id]));
  const [counters, setCounters] = useState({});
  const [isLoadingCounters, setIsLoadingCounters] = useState({});
  const [formData, setFormData] = useState(setupFormData());
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const { isOpen: isOpenCleanCustomerData, onOpen: onOpenCleanCustomerData, onClose: onCloseCleanCustomerData } = useDisclosure();
  const { isOpen: isOpenGenerateContract, onOpen: onOpenGenerateContract, onClose: onCloseGenerateContract } = useDisclosure();
  const toast = useCustomToast();

  useEffect(() => {
    const formData = setupFormData(data);
    setFormData(formData);
  }, [data]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id ? await api.put(`/customers/${_id}`, data) : await api.post("/customers", data);
        navigate(`/customers/edit/${saved._id}`, { replace: true });
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
      } catch (error) {
        if (error.isHandled) return;
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, navigate]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const shape = {
        status: yup.string().required(messages.error.required),
        type: yup.string().required(messages.error.required),
        name: yup.string().required(messages.error.required),
        companyName: yup.string().when("type", {
          is: "pj",
          then: yup.string().required(messages.error.required),
        }),
        document: yup.string().required(messages.error.required),
        segment: yup.string().required(messages.error.required),
        email: yup.string().email(messages.error.invalidEmail).required(messages.error.required),
        phone1: yup.string().required(messages.error.required),
        address: yup.object().shape({
          zipCode: yup.string().required(messages.error.required),
          street: yup.string().required(messages.error.required),
          neighborhood: yup.string().required(messages.error.required),
          city: yup.string().required(messages.error.required),
          state: yup.string().required(messages.error.required),
        }),
        contacts: yup.array().of(
          yup.object().shape({
            name: yup.string().required(messages.error.required),
            office: yup.string().required(messages.error.required),
            email: yup.string().email(messages.error.invalidEmail).required(messages.error.required),
          })
        ),
      };
      const data = {
        ...formData,
        segment: formData.segment?._id,
        contacts: _.filter(formData.contacts, ({ phoneCategory, ...rest }) => _.isEmpty(rest) === false),
        socialNetworks: _.filter(formData.socialNetworks, (value) => _.isEmpty(value) === false),
      };
      const schema = yup.object().shape(shape);
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <CustomersDetailsContext.Provider
      value={{
        formData,
        formErrors,
        setFormData,
        setCounters,
        setIsLoadingCounters,
        refreshData,
      }}
    >
      <Content>
        <HStack justify="space-between">
          <HStack spacing={{ base: "10px", lg: "20px" }}>
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "cadastros" },
                { to: "/customers", label: "clientes" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
          {_id && (
            <HStack>
              {data?.deployedAt && (
                <PermissionedContainer required={["contracts:create", "customers:delete"]}>
                  <Box>
                    <Menu>
                      <MenuButton as={Button} size="sm" variant="outline" rightIcon={<Icon as={MdMoreHoriz} />}>
                        mais ações
                      </MenuButton>
                      <Portal>
                        <MenuList fontSize="sm">
                          <PermissionedContainer required="contracts:create">
                            <MenuItem onClick={onOpenGenerateContract}>
                              <HStack>
                                <Icon as={HiOutlineDocumentText} />
                                <Text>gerar contrato</Text>
                              </HStack>
                            </MenuItem>
                            <MenuDivider />
                          </PermissionedContainer>
                          <PermissionedContainer required="customers:delete">
                            <MenuItem onClick={onOpenCleanCustomerData}>
                              <HStack>
                                <Icon as={FiTrash} />
                                <Text>limpar cliente</Text>
                              </HStack>
                            </MenuItem>
                          </PermissionedContainer>
                        </MenuList>
                      </Portal>
                    </Menu>
                  </Box>
                </PermissionedContainer>
              )}
              <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />
            </HStack>
          )}
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Cliente</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ? data?.name : "Novo cadastro"}</Text>
          </Box>
        </HStack>
        <Tabs index={activeIndex} onChange={(index) => setActiveIndex(index)} colorScheme="main">
          <TabList overflowX="auto" overflowY="hidden" whiteSpace="nowrap">
            <Tab>dados gerais</Tab>
            <Tab>transação</Tab>
            <Tab>
              <HStack>
                <Text>arquivos</Text>
                {isLoadingCounters.files ? (
                  <Spinner size="xs" />
                ) : (
                  <Text fontSize="sm" fontWeight="semibold">
                    ({counters.files ?? 0})
                  </Text>
                )}
              </HStack>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel px="0" py="30px">
              <General />
            </TabPanel>
            <TabPanel px="0" py="30px">
              <Transaction />
            </TabPanel>
            <TabPanel px="0" py="30px">
              <Files />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Content>

      <PermissionedContainer required={"customers:".concat(_id ? "update" : "create")}>
        <Divider />
        <SlideFade in={activeIndex <= 1} hidden={activeIndex > 1} offsetY="20px">
          <HStack p="20px">
            <Button size="sm" colorScheme="main" isLoading={isLoadingData || isLoadingSaveData} onClick={handleSubmit}>
              salvar
            </Button>
            <Button size="sm" variant="ghost" onClick={() => navigate(-1)}>
              voltar
            </Button>
          </HStack>
        </SlideFade>
        <SlideFade in={activeIndex > 1} hidden={activeIndex <= 1} offsetY="20px">
          <HStack p="20px">
            <Button size="sm" variant="ghost" onClick={() => navigate(-1)}>
              voltar
            </Button>
          </HStack>
        </SlideFade>
      </PermissionedContainer>

      {_id && (
        <>
          <DocumentHistory path={`/customers/${_id}/history`} isOpen={isOpenDocumentHistory} onClose={onCloseDocumentHistory} />
          <CleanCustomerData isOpen={isOpenCleanCustomerData} onClose={onCloseCleanCustomerData} />
          <GenerateContract isOpen={isOpenGenerateContract} onClose={onCloseGenerateContract} />
        </>
      )}
    </CustomersDetailsContext.Provider>
  );
};
