import React, { useState, useCallback } from "react";
import _ from "lodash";
import { Button, Divider, HStack, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";
import moment from "moment";
import { useParams } from "react-router";
import { messages } from "consts";
import { api, useCustomToast } from "@core";

const PreviewContract = ({ data, onClose }) => {
  const { _id } = useParams();
  const [isLoadingSendContract, setIsLoadingSendContract] = useState(false);
  const toast = useCustomToast();

  const handleSendContract = useCallback(async () => {
    try {
      setIsLoadingSendContract(true);
      await api.post(`/customers/${_id}/contracts`, data);
      toast({ description: messages.success.sendContract, status: "success", isClosable: true });
      onClose();
    } catch (error) {
      if (error.isHandled) return;
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingSendContract(false);
    }
  }, [_id, toast, onClose]);

  return (
    <Modal size="4xl" isOpen={data?.title} onClose={onClose} isCentered scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>{data?.title}</Text>
          <Text fontSize="md" fontWeight="normal">
            Obrigatório a partir de {moment(data?.deadlineDate).format("DD/MM/YYYY")}
          </Text>
        </ModalHeader>
        <Divider />
        <ModalBody p="40px">
          <div className="contract-wrapper" dangerouslySetInnerHTML={{ __html: data?.body }} />
        </ModalBody>
        <Divider />
        <ModalFooter as={HStack}>
          <Button size="sm" variant="outline" onClick={onClose}>
            fechar
          </Button>
          <Button size="sm" colorScheme="green" isLoading={isLoadingSendContract} onClick={handleSendContract}>
            enviar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PreviewContract;
