import React, { useCallback, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Heading, Icon, PinInput, PinInputField, Text, VStack, HStack } from "@chakra-ui/react";
import _ from "lodash";
import { useCustomToast } from "@core";
import { AuthContext } from "AuthProvider";
import { MdKeyboardArrowRight } from "react-icons/md";
import { __DEV__ } from "index";

export const SignInVerificationCode = () => {
  const { signIn } = useContext(AuthContext);
  const location = useLocation();
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const toast = useCustomToast();

  const handleSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        setIsLoading(true);
        await signIn(location.state._id, formData.verificationCode);
      } catch (error) {
        toast({ description: error.message, status: "error", duration: 5000, isClosable: true });
      } finally {
        setIsLoading(false);
      }
    },
    [toast, location.state, formData]
  );

  return (
    <>
      <Heading textAlign="center" size="lg">
        Código de Verificação
      </Heading>
      <Text fontSize="sm" textAlign="center" mb={4}>
        Por favor, informe o código de verificação enviado para o email <strong>{location.state.email}</strong>.
      </Text>
      <form onSubmit={handleSubmit}>
        <VStack alignItems="stretch" spacing={4}>
          <HStack justifyContent="center">
            <PinInput size="lg" onChange={(verificationCode) => setFormData((state) => ({ ...state, verificationCode }))} autoFocus={true}>
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
          </HStack>
          <Button
            type="submit"
            colorScheme="main"
            rightIcon={<Icon as={MdKeyboardArrowRight} />}
            isDisabled={formData.verificationCode?.length < 6}
            isLoading={isLoading}
          >
            Entrar
          </Button>
        </VStack>
      </form>
    </>
  );
};
