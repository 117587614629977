import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { Center, Box, useColorModeValue } from "@chakra-ui/react";
import { SignInEmailAndCompany, SignInVerificationCode } from "./SignIn";

const Container = () => {
  const backgroundColor = useColorModeValue("white", "gray.900");

  return (
    <Center width="100vw" height="100vh" bg={backgroundColor}>
      <Box borderWidth="1px" borderRadius="xl" width={{ base: "90%", md: "400px" }} p={8}>
        <Outlet />
      </Box>
    </Center>
  );
};

const Public = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Container />}>
          <Route index={true} path="sign-in" element={<SignInEmailAndCompany />} />
          <Route index={true} path="sign-in/verification-code" element={<SignInVerificationCode />} />
        </Route>
        <Route path="*" element={<Navigate to="/sign-in" replace={true} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Public;
