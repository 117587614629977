import React, { useContext } from "react";
import _ from "lodash";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  StackDivider,
  VStack,
} from "@chakra-ui/react";
import { useArrayItemHandlers } from "@core";
import { MdAddCircleOutline, MdOutlineDelete } from "react-icons/md";
import CustomersDetailsContext from "../context";

const SocialNetworks = () => {
  const { formData, setFormData, formErrors } = useContext(CustomersDetailsContext);
  const { handleChangeArrayItem, handleAddArrayItem, handleDeleteArrayItem } = useArrayItemHandlers(setFormData);

  return (
    <>
      <Heading size="sm" mb={6}>
        Redes sociais
      </Heading>
      <VStack align="stretch" divider={<StackDivider opacity={{ base: "1", lg: "0" }} />} spacing={{ base: "30px", lg: "10px" }}>
        {_.map(formData.socialNetworks, (contact, index) => (
          <Grid key={index} templateColumns="repeat(12, 1fr)" gap={4}>
            <GridItem colSpan={{ base: 12, lg: 6 }}>
              <FormControl isInvalid={formErrors.socialNetworks?.[index]?.name}>
                <FormLabel fontSize="sm">Nome da rede social</FormLabel>
                <Input
                  value={contact.name ?? ""}
                  onChange={({ target }) => handleChangeArrayItem("socialNetworks", index, { name: target.value })}
                />
                <FormErrorMessage>{formErrors.socialNetworks?.[index]?.name}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 7, lg: 5 }}>
              <FormControl isInvalid={formErrors.socialNetworks?.[index]?.nick}>
                <FormLabel fontSize="sm">Nick</FormLabel>
                <InputGroup>
                  <InputLeftAddon fontSize="sm">@</InputLeftAddon>
                  <Input
                    value={contact.nick ?? ""}
                    onChange={({ target }) => handleChangeArrayItem("socialNetworks", index, { nick: target.value })}
                  />
                </InputGroup>
                <FormErrorMessage>{formErrors.socialNetworks?.[index]?.nick}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 5, lg: 1 }}>
              <FormLabel fontSize="sm">&nbsp;</FormLabel>
              <Button
                rightIcon={<Icon as={MdOutlineDelete} />}
                size="md"
                variant="outline"
                onClick={() => handleDeleteArrayItem("socialNetworks", index)}
              >
                deletar
              </Button>
            </GridItem>
          </Grid>
        ))}
      </VStack>
      <Button
        size="sm"
        colorScheme="main"
        variant="ghost"
        mt={4}
        leftIcon={<Icon as={MdAddCircleOutline} />}
        onClick={() => handleAddArrayItem("socialNetworks")}
      >
        adicionar rede social
      </Button>
    </>
  );
};

export default SocialNetworks;
