import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  HStack,
  Input,
  Text,
} from "@chakra-ui/react";
import { api, useCustomToast } from "@core";
import { useParams } from "react-router-dom";
import { messages } from "consts";

const requiredConfirmText = "limpar permanentemente";

const CleanCustomerData = ({ isOpen, onClose }) => {
  const { _id } = useParams();
  const [confirmText, setConfirmText] = useState("");
  const [isLoadingCleanData, setIsLoadingCleanData] = useState(false);
  const toast = useCustomToast();

  useEffect(() => {
    setConfirmText("");
  }, [isOpen]);

  const handleCleanCustomerData = useCallback(async () => {
    try {
      setIsLoadingCleanData(true);
      await api.put(`customers/${_id}/clean`);
      toast({ description: messages.success.cleanData, status: "success", isClosable: true });
      onClose();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingCleanData(false);
    }
  }, [onClose]);

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} isCentered>
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>Atenção</AlertDialogHeader>
        <AlertDialogBody>
          <Text mb="10px">
            Deseja realmente limpar todos os dados do cliente selecionado? <strong>Este é um processo irreversível</strong>.
          </Text>
          <Text mb="20px">
            Para continuar digite <i>{requiredConfirmText}</i> no campo abaixo.
          </Text>
          <Input variant="filled" value={confirmText} onChange={({ target }) => setConfirmText(target.value)} />
        </AlertDialogBody>
        <AlertDialogFooter as={HStack} justify="flex-end">
          <Button size="sm" onClick={onClose}>
            cancelar
          </Button>
          <Button
            size="sm"
            colorScheme="red"
            isDisabled={confirmText !== requiredConfirmText}
            isLoading={isLoadingCleanData}
            onClick={handleCleanCustomerData}
          >
            limpar
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default CleanCustomerData;
