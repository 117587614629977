import axios from "axios";
import * as EventEmitter from "./eventEmitter";

const errorHandler = {
  INVALID_AUTHORIZATION: (error) => EventEmitter.dispatch("onAuthorizationError", error),
  PERMISSION_DENIED: (error) => EventEmitter.dispatch("onPermissionDenied", error),
  APP_VERSION: (error) => EventEmitter.dispatch("onAppVersionError", error),
};

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 180000,
});

api.interceptors.request.use(
  async (config) => {
    try {
      config.headers.authorization = localStorage.getItem("@token");
      config.headers.os = "default";
      config.headers.packagename = process.env.REACT_APP_PACKAGE_NAME;
      config.headers.appversion = process.env.REACT_APP_VERSION;
      return config;
    } catch (error) {
      return config;
    }
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  ({ data }) => {
    if (!data.error) return data;
    errorHandler[data.error?.type]?.(data.error);
    return Promise.reject(data.error);
  },
  (error) => {
    if (error.code === "ERR_NETWORK") EventEmitter.dispatch("onNetworkError", error);
    else errorHandler[error?.response?.data?.type]?.(error?.response?.data);
    return Promise.reject(error?.response?.data ?? error.message);
  }
);

api.upload = (path, file = {}, data = {}) => {
  const formData = new FormData();
  formData.append("file", file, file.name);
  formData.append("data", JSON.stringify(data));
  return api.post(path, formData, {
    headers: {
      accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
    },
  });
};
