export const customers = [
  { color: "blue.500", value: "prospect" },
  { color: "yellow.500", value: "pending" },
  { color: "green.500", value: "deployed" },
  { color: "gray.300", value: "suspended" },
  { color: "red.500", value: "canceled" },
];

export const transactions = [
  { color: "yellow.500", value: "pending" },
  { color: "green.500", value: "paid" },
  { color: "red.500", value: "refunded" },
];

export const contracts = [
  { color: "yellow.500", value: "waiting_accept" },
  { color: "green.500", value: "accepted" },
  { color: "red.500", value: "canceled" },
];
