import React, { useMemo, useRef, useState, useCallback, useEffect } from "react";
import { Box, Center, Divider, Heading, HStack, SlideFade, Spinner, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import { Breadcrumb, Paginator, TableDrawer, TableEmpty, useApiGet, useDocumentTitle, useTable } from "@core";
import { Content, ContentBody, ContentHeader } from "pages/Private/Container";
import Filters from "./filters";
import defaultColumns from "./defaultColumns";

export const ContractsList = () => {
  useDocumentTitle("Contratos");
  const location = useLocation();
  const [sort, setSort] = useState({ nid: 1 });
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(100);
  const [search, setSearch] = useState("");
  const [query, setQuery] = useState();
  const [data, isLoadingData, refreshData] = useApiGet(
    useMemo(
      () => ({
        path: "/contracts",
        params: { query, sort, page, perPage, search },
        options: { isEnabled: _.isObject(query) },
      }),
      [query, sort, page, perPage, search]
    )
  );
  const { columns, cells, updateTableColumns } = useTable(
    useMemo(() => ({ id: location.pathname, defaultColumns, _v: 3 }), [location.pathname])
  );
  const tableDrawerRef = useRef();

  const handleTableDrawerChange = useCallback(
    ({ sort, perPage, columns }) => {
      setSort(sort);
      setPerPage(perPage);
      updateTableColumns(columns);
    },
    [updateTableColumns]
  );

  return (
    <>
      <Content>
        <ContentHeader>
          <HStack justify="space-between">
            <Breadcrumb items={[{ label: "cadastros" }, { to: "/contracts", label: "contratos" }]} />
          </HStack>
          <Heading my="15px" size="md">
            Contratos
          </Heading>
          <Filters
            onSearch={setSearch}
            onQuery={setQuery}
            onRefresh={refreshData}
            onPage={setPage}
            isLoading={isLoadingData}
            onTableDrawer={() => tableDrawerRef.current.open()}
          />
        </ContentHeader>

        <ContentBody>
          <Table size="sm" whiteSpace="nowrap">
            <Thead>
              <Tr>
                {cells.map(({ accessor, title }) => (
                  <Th key={accessor}>{title}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {_.map(data?.data, (item) => (
                <Tr key={item._id} _hover={{ _light: { bg: "gray.50" }, _dark: { bg: "gray.900" } }}>
                  {cells.map(({ accessor, formatter, render }) => (
                    <Td key={accessor}>{formatter?.(item) ?? render?.(item)}</Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
          {isLoadingData && (
            <Center p="30px">
              <Spinner />
            </Center>
          )}
          <TableEmpty isLoading={isLoadingData} size={_.size(data?.data)} />
        </ContentBody>
      </Content>

      <Divider />

      <SlideFade in={true} offsetY="20px">
        <Box p="20px">
          <Paginator loading={isLoadingData} page={page} size={data?.size} perPage={perPage} onPaginate={setPage} />
        </Box>
      </SlideFade>

      <TableDrawer
        ref={tableDrawerRef}
        defaultColumns={defaultColumns}
        columns={columns}
        sort={sort}
        perPage={perPage}
        onChange={handleTableDrawerChange}
      />
    </>
  );
};
