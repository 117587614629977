import moment from "moment";
import { Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    formatter: ({ nid }) => nid,
  },
  {
    accessor: "name",
    title: "Nome",
    isVisible: true,
    render: ({ _id, name, avatar }) => (
      <Link as={RouterLink} to={`edit/${_id}`}>
        {name}
      </Link>
    ),
    exporter: ({ name }) => name,
  },
  {
    accessor: "email",
    title: "E-mail",
    isVisible: true,
    formatter: ({ email }) => email,
  },
  {
    accessor: "userGroup",
    title: "Grupo",
    isVisible: true,
    isSortable: false,
    formatter: ({ userGroup }) => userGroup?.title ?? "-",
  },
  {
    accessor: "isActive",
    title: "Ativo?",
    isVisible: true,
    formatter: ({ isActive }) => (isActive ? "Sim" : "Não"),
  },
  {
    accessor: "createdBy",
    title: "Criado por",
    isVisible: true,
    isSortable: false,
    formatter: ({ createdBy }) => createdBy?.name ?? "-",
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualizado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
