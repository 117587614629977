import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "consts";
import moment from "moment";
import "moment/locale/pt-br";
import AppProvider from "./AppProvider";
import "./style.css";

moment.locale("pt-br");

export const __DEV__ = process.env.NODE_ENV === "development";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ChakraProvider theme={theme}>
    <AppProvider />
  </ChakraProvider>
);
