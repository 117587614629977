import React, { useEffect } from "react";
import {
  Flex,
  Text,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Heading,
  useToken,
} from "@chakra-ui/react";
import { Lottie, EventEmitter } from "@core";
import { MdClose } from "react-icons/md";
import animationData from "@core/assets/lottie/lock.json";

export const PermissionDenied = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [main100, main200, main500] = useToken("colors", ["main.100", "main.200", "main.500"]);

  useEffect(() => {
    EventEmitter.subscribe("onPermissionDenied", onOpen);
  }, [onOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody as={Flex} direction="column" justifyContent="center" alignItems="center" padding="30px">
          <Lottie
            replaceColors={[
              { from: [8, 69, 221], to: main500 },
              { from: [130, 166, 253], to: main200 },
              { from: [178, 202, 255], to: main100 },
            ]}
            animationData={animationData}
          />
          <Heading size="lg">Acesso negado</Heading>
          <Text fontSize="sm" textAlign="center" mb="20px">
            Acesso negado. Talvez você não tenha as permissões necessárias para acessar este recurso.
          </Text>
          <Button variant="outline" leftIcon={<Icon as={MdClose} />} onClick={onClose}>
            fechar
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
