import React, { useState, useEffect, useMemo, useCallback } from "react";
import _ from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  Select,
  SlideFade,
  Spinner,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { BoxData, Breadcrumb, DocumentHistory, api, useApiGet, useCustomToast, useDocumentTitle } from "@core";
import * as yup from "yup";
import { messages } from "consts";
import { PermissionedContainer } from "components";
import { Content } from "pages/Private/Container";
import { MdChevronLeft, MdHistory } from "react-icons/md";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import Preview from "./preview";
import ContractsDetailsContext from "./context";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export const ContractTemplatesDetails = () => {
  const { _id } = useParams();
  useDocumentTitle(_id ? "Editar template de contrato" : "Novo template de contrato");
  const navigate = useNavigate();
  const location = useLocation();
  const [data, isLoadingData, refreshData] = useApiGet(useMemo(() => ({ path: `/contract-templates/${_id}` }), [_id]));
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);
  const [editorState, setEditorState] = useState();
  const { isOpen: isOpenDocumentHistory, onOpen: onOpenDocumentHistory, onClose: onCloseDocumentHistory } = useDisclosure();
  const toast = useCustomToast();

  useEffect(() => {
    const { body, ...formData } = data ?? { deadlineInDays: "30", isActive: true };
    const blocksFromHtml = htmlToDraft(body ?? "");
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    setEditorState(editorState);
    setFormData(formData);
  }, [data]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        const saved = _id ? await api.put(`/contract-templates/${_id}`, data) : await api.post("/contract-templates", data);
        navigate(`/contract-templates/edit/${saved._id}`, { replace: true });
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
        refreshData();
      } catch (error) {
        if (error.isHandled) return;
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [_id, refreshData, toast, navigate]
  );

  const handleSubmit = useCallback(
    async (e, defaultData = {}) => {
      try {
        const shape = {
          title: yup.string().required(messages.error.required),
          deadlineInDays: yup.string().required(messages.error.required),
          body: yup.string().required(messages.error.required),
        };
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const data = { ...formData, ...defaultData, body: draftToHtml(rawContentState) };
        const schema = yup.object().shape(shape);
        await schema.validate(data, { abortEarly: false });
        handleSaveData(data);
        setFormErrors({});
      } catch (error) {
        const formErrors = {};
        for (const { path, message } of error.inner) _.set(formErrors, path, message);
        setFormErrors(formErrors);
      }
    },
    [formData, editorState, handleSaveData]
  );

  return (
    <ContractsDetailsContext.Provider value={{ formData, editorState, handleSubmit, isLoadingSaveData }}>
      <Content>
        <HStack justify="space-between">
          <HStack spacing={{ base: "10px", lg: "20px" }}>
            <Button size="sm" variant="outline" leftIcon={<Icon as={MdChevronLeft} />} onClick={() => navigate(-1)}>
              voltar
            </Button>
            <Breadcrumb
              items={[
                { label: "cadastros" },
                { to: "/contract-templates", label: "templates de contratos" },
                { to: location.pathname, label: _id ? "editar" : "novo" },
              ]}
            />
          </HStack>
          <HStack>
            <Box>
              <Preview />
            </Box>
            {_id && <IconButton size="sm" variant="outline" icon={<Icon as={MdHistory} />} onClick={onOpenDocumentHistory} />}
          </HStack>
        </HStack>

        <HStack my="15px" justify="space-between">
          <Box>
            <HStack>
              <Heading size="md">Template de template de contrato</Heading>
              {isLoadingData && <Spinner size="sm" />}
            </HStack>
            <Text fontSize="sm">{_id ? data?.title : "Novo cadastro"}</Text>
          </Box>
          <HStack>
            <FormControl display="flex" alignItems="center">
              <FormLabel fontSize="sm" mb="0">
                Ativo?
              </FormLabel>
              <Switch
                colorScheme="main"
                isChecked={formData.isActive}
                onChange={() => setFormData((state) => ({ ...state, isActive: !state.isActive }))}
              />
            </FormControl>
          </HStack>
        </HStack>

        <Grid templateColumns="repeat(12, 1fr)" gap={4} mb={8}>
          <GridItem colSpan={{ base: 4, lg: 1 }}>
            <BoxData label="NID" value={formData.nid?.toLocaleString() ?? "-"} />
          </GridItem>
          <GridItem colSpan={{ base: 12, lg: 8 }}>
            <FormControl isRequired={true} isInvalid={formErrors.title}>
              <FormLabel fontSize="sm">Título</FormLabel>
              <Input value={formData.title ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, title: target.value }))} />
              <FormErrorMessage>{formErrors.title}</FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={{ base: 8, lg: 3 }}>
            <FormControl isRequired={true} isInvalid={formErrors.deadlineInDays}>
              <FormLabel fontSize="sm">Prazo para obrigatoriedade</FormLabel>
              <Select
                value={formData.deadlineInDays}
                onChange={({ target }) => setFormData((state) => ({ ...state, deadlineInDays: target.value }))}
              >
                <option value="0">0 dias</option>
                <option value="30">30 dias</option>
                <option value="60">60 dias</option>
                <option value="90">90 dias</option>
              </Select>
              <FormErrorMessage>{formErrors.deadlineInDays}</FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>

        <Box className="contract-wrapper">
          <Editor editorState={editorState} onEditorStateChange={(editorState) => setEditorState(editorState)} />
        </Box>
      </Content>

      <PermissionedContainer required={"contract-templates:".concat(_id ? "update" : "create")}>
        <Divider />
        <SlideFade in={true} offsetY="20px">
          <HStack p="20px" justifyContent="space-between">
            <HStack>
              <Button size="sm" colorScheme="main" isLoading={isLoadingData || isLoadingSaveData} onClick={handleSubmit}>
                salvar
              </Button>
              <Button size="sm" variant="ghost" onClick={() => navigate(-1)}>
                voltar
              </Button>
            </HStack>
          </HStack>
        </SlideFade>
      </PermissionedContainer>

      {_id && (
        <DocumentHistory path={`/contract-templates/${_id}/history`} isOpen={isOpenDocumentHistory} onClose={onCloseDocumentHistory} />
      )}
    </ContractsDetailsContext.Provider>
  );
};
