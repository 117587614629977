const terms = {
  prospect: "Prospecto",
  pending: "Pendente",
  deployed: "Implantado",
  suspended: "Suspenso",
  canceled: "Cancelado",
  paid: "Pago",
  refunded: "Estornado",
  waiting_accept: "Aguardando aceitação",
  accepted: "Aceito",
};

export const translator = (word) => {
  const term = word?.toLowerCase?.();
  if (terms[term]) return terms[term];
  return word;
};
