export const currency = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

export const percent = new Intl.NumberFormat("pt-BR", {
  style: "percent",
  maximumFractionDigits: 2,
});

export const accuratePercent = new Intl.NumberFormat("pt-BR", {
  style: "percent",
  minimumFractionDigits: 5,
  maximumFractionDigits: 5,
});
