import React, { useCallback, useMemo, useState } from "react";
import _ from "lodash";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Select,
} from "@chakra-ui/react";
import { api, useApiGet, useCustomToast } from "@core";
import { useParams } from "react-router-dom";
import { messages } from "consts";
import { yup } from "lib";
import { MdRefresh } from "react-icons/md";
import PreviewContract from "./previewContract";

const GenerateContract = ({ isOpen, onClose }) => {
  const { _id } = useParams();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingGenerateContract, setIsLoadingGenerateContract] = useState(false);
  const [generatedContract, setGeneratedContract] = useState();
  const [contractTemplates, isLoadingContractTemplates, refreshContractTemplates] = useApiGet(
    useMemo(
      () => ({
        path: "/contract-templates",
        params: {
          query: { isActive: true },
          sort: { title: 1 },
          perPage: -1,
          isAutocomplete: true,
        },
      }),
      []
    )
  );
  const toast = useCustomToast();

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingGenerateContract(true);
        const generatedContract = await api.put(`/customers/${_id}/contracts`, data);
        setGeneratedContract(generatedContract);
        onClose();
      } catch (error) {
        if (error.isHandled) return;
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setIsLoadingGenerateContract(false);
      }
    },
    [_id, toast, onClose]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const shape = {
        contractTemplate: yup.string().required(messages.error.required),
      };
      const schema = yup.object().shape(shape);
      await schema.validate(formData, { abortEarly: false });
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      const formErrors = {};
      for (const { path, message } of error.inner) _.set(formErrors, path, message);
      setFormErrors(formErrors);
    }
  }, [formData, handleSaveData]);

  return (
    <>
      <AlertDialog isOpen={isOpen} onClose={onClose} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Gerar contrato</AlertDialogHeader>
          <AlertDialogBody>
            <FormControl isRequired={true} isInvalid={formErrors.contractTemplate}>
              <FormLabel>Template do contrato</FormLabel>
              <HStack>
                <Select
                  value={formData.contractTemplate ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, contractTemplate: target.value }))}
                >
                  <option>--Selecione</option>
                  {_.map(contractTemplates?.data, (item) => (
                    <option key={item._id} value={item._id}>
                      {item.title}
                    </option>
                  ))}
                </Select>
                <IconButton
                  variant="outline"
                  icon={<Icon as={MdRefresh} />}
                  isLoading={isLoadingContractTemplates}
                  onClick={refreshContractTemplates}
                />
              </HStack>
              <FormErrorMessage>{formErrors.contractTemplate}</FormErrorMessage>
            </FormControl>
          </AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button size="sm" onClick={onClose}>
              cancelar
            </Button>
            <Button size="sm" colorScheme="green" isLoading={isLoadingGenerateContract} onClick={handleSubmit}>
              gerar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <PreviewContract data={generatedContract} onClose={() => setGeneratedContract()} />
    </>
  );
};

export default GenerateContract;
