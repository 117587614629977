import moment from "moment";
import { CustomBadge } from "components";

const defaultColumns = [
  {
    accessor: "uuid",
    title: "UUID",
    isVisible: true,
    formatter: ({ _id }) => _id,
  },
  {
    accessor: "status",
    title: "Status",
    isVisible: true,
    render: ({ status }) => <CustomBadge.Contracts.Status status={status} />,
  },
  {
    accessor: "title",
    title: "Título",
    isVisible: true,
    render: ({ title }) => title ?? "-",
  },
  {
    accessor: "company.nid",
    title: "NID/Empresa",
    isVisible: true,
    render: ({ company }) => company?.nid ?? "-",
  },
  {
    accessor: "company.name",
    title: "Nome/Empresa",
    isVisible: true,
    render: ({ company }) => company?.name ?? "-",
  },
  {
    accessor: "company.email",
    title: "E-mail/Empresa",
    isVisible: true,
    render: ({ company }) => company?.email ?? "-",
  },
  {
    accessor: "acceptedAt",
    title: "Aceito em",
    isVisible: true,
    formatter: ({ acceptedAt }) => (acceptedAt ? moment(acceptedAt).format("DD/MM/YYYY HH:mm") : "-"),
  },
  {
    accessor: "canceledAt",
    title: "Cancelado em",
    isVisible: true,
    formatter: ({ canceledAt }) => (canceledAt ? moment(canceledAt).format("DD/MM/YYYY HH:mm") : "-"),
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualizado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
