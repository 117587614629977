import { useRef, useEffect, useContext } from "react";
import { PrivateContext } from "pages/Private";

export const useDocumentTitle = (title, prevailOnUnmount = false) => {
  const defaultTitle = useRef(document.title);
  const { notificationsCount } = useContext(PrivateContext);

  useEffect(() => {
    let _title = process.env.REACT_APP_NAME;
    if (notificationsCount >= 1) _title += ` (${notificationsCount})`;
    document.title = `${_title} | ${title}`;
  }, [notificationsCount, title]);

  useEffect(() => {
    return () => {
      if (!prevailOnUnmount) document.title = defaultTitle.current;
    };
  }, []);
};
