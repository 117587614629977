import React, { useContext } from "react";
import _ from "lodash";
import { FormControl, FormErrorMessage, FormLabel, Grid, GridItem, Input, InputGroup, InputLeftAddon, Select } from "@chakra-ui/react";
import InputMask from "react-input-mask";
import { EmailAutocomplete, InputCurrency, PhoneInput, personTypes } from "@core";
import CustomersDetailsContext from "../context";

const Transaction = () => {
  const { formData, setFormData, formErrors } = useContext(CustomersDetailsContext);

  return (
    <Grid templateColumns="repeat(12, 1fr)" gap={4}>
      <GridItem colSpan={{ base: 12, lg: 2 }}>
        <FormControl isInvalid={formErrors.transaction?.id}>
          <FormLabel fontSize="sm">ID</FormLabel>
          <Input
            value={formData.transaction?.id ?? ""}
            onChange={({ target }) => setFormData((state) => ({ ...state, transaction: { ...state.transaction, id: target.value } }))}
          />
          <FormErrorMessage>{formErrors.transaction?.id}</FormErrorMessage>
        </FormControl>
      </GridItem>
      <GridItem colSpan={{ base: 12, lg: 2 }}>
        <FormControl isInvalid={formErrors.transaction?.status}>
          <FormLabel fontSize="sm">Status</FormLabel>
          <Select
            value={formData.transaction?.status ?? ""}
            onChange={({ target }) => setFormData((state) => ({ ...state, transaction: { ...state.transaction, status: target.value } }))}
          >
            <option value="">--Selecione</option>
            <option value="pending">Pendente</option>
            <option value="paid">Pago</option>
            <option value="refunded">Estornado</option>
          </Select>
          <FormErrorMessage>{formErrors.transaction?.status}</FormErrorMessage>
        </FormControl>
      </GridItem>
      <GridItem colSpan={{ base: 12, lg: 2 }}>
        <FormControl isInvalid={formErrors.transaction?.type}>
          <FormLabel fontSize="sm">Tipo de pessoa</FormLabel>
          <Select
            value={formData.transaction?.type ?? ""}
            onChange={({ target }) => setFormData((state) => ({ ...state, transaction: { ...state.transaction, type: target.value } }))}
          >
            <option value="">--Selecione</option>
            <option value="pf">Pessoa física</option>
            <option value="pj">Pessoa jurídica</option>
          </Select>
          <FormErrorMessage>{formErrors.transaction?.type}</FormErrorMessage>
        </FormControl>
      </GridItem>
      <GridItem colSpan={{ base: 12, lg: 6 }}>
        <FormControl isInvalid={formErrors.transaction?.name}>
          <FormLabel fontSize="sm">Nome</FormLabel>
          <Input
            value={formData.transaction?.name ?? ""}
            onChange={({ target }) => setFormData((state) => ({ ...state, transaction: { ...state.transaction, name: target.value } }))}
          />
          <FormErrorMessage>{formErrors.transaction?.name}</FormErrorMessage>
        </FormControl>
      </GridItem>

      <GridItem colSpan={{ base: 12, lg: 4 }}>
        <FormControl isInvalid={formErrors.transaction?.document}>
          <FormLabel fontSize="sm">Documento</FormLabel>
          <Input
            as={InputMask}
            mask={personTypes[formData.transaction?.type ?? "pf"].documentMask}
            value={formData.transaction?.document ?? ""}
            onChange={({ target }) => setFormData((state) => ({ ...state, transaction: { ...state.transaction, document: target.value } }))}
          />
          <FormErrorMessage>{formErrors.transaction?.document}</FormErrorMessage>
        </FormControl>
      </GridItem>
      <GridItem colSpan={{ base: 12, lg: 4 }}>
        <FormControl isRequired={false} isInvalid={formErrors.transaction?.email}>
          <FormLabel fontSize="sm">E-mail</FormLabel>
          <EmailAutocomplete
            value={formData.transaction?.email ?? ""}
            onChange={(email) => setFormData((state) => ({ ...state, transaction: { ...state.transaction, email } }))}
          />
          <FormErrorMessage>{formErrors.transaction?.email}</FormErrorMessage>
        </FormControl>
      </GridItem>
      <GridItem colSpan={{ base: 12, lg: 4 }}>
        <PhoneInput
          label="Telefone"
          phone={formData.transaction?.phone}
          category={formData.transaction?.phoneCategory}
          onChangePhone={(phone) => setFormData((state) => ({ ...state, transaction: { ...state.transaction, phone } }))}
          onChangeCategory={(phoneCategory) => setFormData((state) => ({ ...state, transaction: { ...state.transaction, phoneCategory } }))}
          isRequired={false}
          isInvalid={formErrors.transaction?.phone}
        />
      </GridItem>

      <GridItem colSpan={{ base: 12, lg: 6 }}>
        <FormControl isInvalid={formErrors.transaction?.capturedAmount}>
          <FormLabel fontSize="sm">Valor capturado</FormLabel>
          <InputGroup>
            <InputLeftAddon>R$</InputLeftAddon>
            <Input
              as={InputCurrency}
              value={formData.transaction?.capturedAmount ?? ""}
              onChange={(capturedAmount) => setFormData((state) => ({ ...state, transaction: { ...state.transaction, capturedAmount } }))}
            />
          </InputGroup>
          <FormErrorMessage>{formErrors.transaction?.capturedAmount}</FormErrorMessage>
        </FormControl>
      </GridItem>
      <GridItem colSpan={{ base: 12, lg: 6 }}>
        <FormControl isInvalid={formErrors.transaction?.receivedAmount}>
          <FormLabel fontSize="sm">Valor líquido</FormLabel>
          <InputGroup>
            <InputLeftAddon>R$</InputLeftAddon>
            <Input
              as={InputCurrency}
              value={formData.transaction?.receivedAmount ?? ""}
              onChange={(receivedAmount) => setFormData((state) => ({ ...state, transaction: { ...state.transaction, receivedAmount } }))}
            />
          </InputGroup>
          <FormErrorMessage>{formErrors.transaction?.receivedAmount}</FormErrorMessage>
        </FormControl>
      </GridItem>
    </Grid>
  );
};

export default Transaction;
