import React, { createContext, useContext } from "react";
import { Center, Spinner, useMediaQuery } from "@chakra-ui/react";
import { Private, Public } from "pages";
import { InvalidAuthorization, NetworkError, PermissionDenied, AppVersionError } from "@core";
import { AuthProvider, AuthContext } from "AuthProvider";

export const AppContext = createContext();

const AppProvider = () => {
  const { currentAuth } = useContext(AuthContext);
  const [isMobileView] = useMediaQuery("(max-width: 940px)");

  if (currentAuth === undefined)
    return (
      <Center h="100vh">
        <Spinner />
      </Center>
    );
  return (
    <AppContext.Provider value={{ isMobileView }}>
      {currentAuth ? <Private /> : <Public />}
      <NetworkError />
      <PermissionDenied />
      <InvalidAuthorization />
      <AppVersionError />
    </AppContext.Provider>
  );
};

export default () => (
  <AuthProvider>
    <AppProvider />
  </AuthProvider>
);
