import React, { useContext, useMemo } from "react";
import _ from "lodash";
import { Button, Divider, HStack, Icon, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { HiOutlineDocumentText } from "react-icons/hi";
import ContractsDetailsContext from "./context";

const Preview = () => {
  const { editorState } = useContext(ContractsDetailsContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const __html = useMemo(() => {
    if (isOpen) {
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      return draftToHtml(rawContentState);
    }
  }, [isOpen]);

  return (
    <>
      <Button size="sm" variant="outline" leftIcon={<Icon as={HiOutlineDocumentText} />} onClick={onOpen}>
        visualizar
      </Button>
      <Modal size="4xl" isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalBody p="40px">
            <div className="contract-wrapper" dangerouslySetInnerHTML={{ __html }} />
          </ModalBody>
          <Divider />
          <ModalFooter as={HStack}>
            <Button size="sm" variant="outline" onClick={onClose}>
              fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Preview;
