import moment from "moment";
import { Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { currency, masks } from "@core";
import { translator } from "lib";
import { CustomBadge } from "components";

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    formatter: ({ nid }) => nid,
  },
  {
    accessor: "status",
    title: "Status",
    isVisible: true,
    render: ({ status }) => <CustomBadge.Customers.Status status={status} />,
    exporter: ({ status }) => translator(status),
  },
  {
    accessor: "transaction.status",
    title: "Status transação",
    isVisible: true,
    render: ({ transaction }) => (transaction?.status ? <CustomBadge.Transactions.Status status={transaction?.status} /> : "-"),
    exporter: ({ transaction }) => (transaction ? translator(transaction?.status) : "-"),
  },
  {
    accessor: "name",
    title: "Nome",
    isVisible: true,
    render: ({ _id, name }) => (
      <Link as={RouterLink} to={`edit/${_id}`}>
        {name}
      </Link>
    ),
    exporter: ({ name }) => name,
  },
  {
    accessor: "companyName",
    title: "Razão social",
    isVisible: false,
    formatter: ({ companyName }) => companyName,
  },
  {
    accessor: "document",
    title: "Documento",
    isVisible: true,
    formatter: ({ document }) => masks.document(document),
  },
  {
    accessor: "phone1",
    title: "Telefone 1",
    isVisible: true,
    formatter: ({ phone1 }) => masks.phone(phone1),
  },
  {
    accessor: "phone2",
    title: "Telefone 2",
    isVisible: false,
    formatter: ({ phone2 }) => masks.phone(phone2),
  },
  {
    accessor: "phone3",
    title: "Telefone 3",
    isVisible: false,
    formatter: ({ phone3 }) => masks.phone(phone3),
  },
  {
    accessor: "email",
    title: "E-mail",
    isVisible: true,
    formatter: ({ email }) => email ?? "-",
  },
  {
    accessor: "segment",
    title: "Segmento",
    isVisible: false,
    isSortable: false,
    formatter: ({ segment }) => segment?.title ?? "-",
  },
  {
    accessor: "deployedAt",
    title: "Implantado em",
    isVisible: true,
    formatter: ({ deployedAt }) => (deployedAt ? moment(deployedAt).format("DD/MM/YYYY HH:mm") : "-"),
  },
  {
    accessor: "transaction.id",
    title: "ID transação",
    isVisible: false,
    formatter: ({ transaction }) => transaction?.id ?? "-",
  },
  {
    accessor: "transaction.name",
    title: "Nome transação",
    isVisible: false,
    formatter: ({ transaction }) => transaction?.name ?? "-",
  },
  {
    accessor: "transaction.document",
    title: "Documento transação",
    isVisible: false,
    formatter: ({ transaction }) => masks.document(transaction?.document),
  },
  {
    accessor: "transaction.email",
    title: "E-mail transação",
    isVisible: false,
    formatter: ({ transaction }) => transaction?.email ?? "-",
  },
  {
    accessor: "transaction.phone",
    title: "Telefone transação",
    isVisible: false,
    formatter: ({ transaction }) => masks.phone(transaction?.phone),
  },
  {
    accessor: "transaction.capturedAmount",
    title: "Valor capturado",
    isVisible: false,
    formatter: ({ transaction }) => currency.format(transaction?.capturedAmount || 0),
    renderFoot: ({ capturedAmount }) => currency.format(capturedAmount || 0),
  },
  {
    accessor: "transaction.receivedAmount",
    title: "Valor líquido",
    isVisible: false,
    formatter: ({ transaction }) => currency.format(transaction?.receivedAmount || 0),
    renderFoot: ({ receivedAmount }) => currency.format(receivedAmount || 0),
  },
  {
    accessor: "createdBy",
    title: "Criado por",
    isVisible: true,
    isSortable: false,
    formatter: ({ createdBy }) => createdBy?.name ?? "-",
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "updatedAt",
    title: "Atualizado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
